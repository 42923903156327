import React from 'react'

import { PurpleLighten25 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = PurpleLighten25 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-0.000244141" width="22.0005" height="22.0005" rx="11.0002" fill={fill} />
      <path
        d="M7.04329 11.1334C6.67379 10.7873 6.09369 10.8063 5.74759 11.1758C5.40149 11.5453 5.42047 12.1254 5.78996 12.4715L7.04329 11.1334ZM9.47357 14.6658L10.1008 15.3343L10.1065 15.3289L9.47357 14.6658ZM8.84549 13.9981C8.47627 14.3445 8.45778 14.9246 8.80419 15.2938C9.15059 15.663 9.73071 15.6815 10.0999 15.3351L8.84549 13.9981ZM16.8276 8.91328C17.1939 8.56371 17.2074 7.98345 16.8578 7.61723C16.5082 7.25102 15.928 7.23752 15.5617 7.58709L16.8276 8.91328ZM5.78996 12.4715L8.8469 15.3348L10.1002 13.9967L7.04329 11.1334L5.78996 12.4715ZM8.84635 13.9973L8.84549 13.9981L10.0999 15.3351L10.1008 15.3343L8.84635 13.9973ZM10.1065 15.3289L16.8276 8.91328L15.5617 7.58709L8.84062 14.0027L10.1065 15.3289Z"
        fill="white"
      />
    </svg>
  )
}

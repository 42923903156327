import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import * as Sentry from '@sentry/browser'
import hmacSHA256 from 'crypto-js/hmac-sha256'

import { AllStartupProps, IStartupPropsFromState } from './types'

import { Mixpanel } from '@/analytics'
import Loader from '@/components/Loader'
import { openAppModal, setAppLoaded } from '@/store/app'
import { IModal, MODALS } from '@/store/app/types'
import { getAvailablePolls, getUserVotes } from '@/store/polls'
import { getReferral } from '@/store/referral'
import { MyThunkDispatch } from '@/store/store'
import {
  getProfile,
  getUserAvailablePlugins,
  getUserInfo,
  getUserPlugins,
  logoutUser,
  refreshToken,
  setAuthorized,
} from '@/store/user'
import { CarrotQuestEvents, CloseDocTypes } from '@/types'
import IApplicationState from '@/types/state'
import { baseCatchError, jwt } from '@/utils'

export class Startup extends PureComponent<AllStartupProps> {
  constructor(props: AllStartupProps) {
    super(props)

    this.setLoadedWithoutAuth = this.setLoadedWithoutAuth.bind(this)
  }

  public componentDidMount() {
    const tokens = jwt.get()

    window.localStorage.setItem('activeFilter', '')

    Mixpanel.track('VisitTool')

    if (tokens.accessToken || tokens.refToken) {
      this.props
        .getUserInfo()
        .then((resp: any) => {
          if (resp.status === 200) {
            Mixpanel.identify(this.props.email)

            const winw = window as any

            if (winw.carrotquest && winw.carrotquest.auth && this.props.user.id && process.env.REACT_APP_CC_AUTH_KEY) {
              const userId = this.props.user.id.toString()
              const hmac = hmacSHA256(userId, process.env.REACT_APP_CC_AUTH_KEY).toString()
              winw.carrotquest.auth(userId, hmac)
              winw.carrotquest.track(CarrotQuestEvents.AUTH, {
                $email: this.props.email.toLowerCase(),
                $name: this.props.user.profile.name,
              })
            }

            this.props
              .getProfile()
              .then((response: any) => {
                if (response.status === 200) {
                  Sentry.configureScope((scope) => {
                    scope.setUser({ email: this.props.email })
                  })

                  Mixpanel.set({
                    $email: this.props.email.toLowerCase(),
                    $name: this.props.user.profile.name,
                    'Archived AdAccounts': this.props.user.profile.account_counts.archived_accounts,
                    'Client ID': this.props.user.profile.id,
                    'Facebook AdAccounts': this.props.user.profile.account_counts.facebook,
                    'Google AdAccounts': this.props.user.profile.account_counts.google,
                    Industry: this.props.user.profile.industry,
                    'Paying Customer': this.props.user.profile.paying_customer ? 'Yes' : 'No',
                    'TikTok AdAccounts': this.props.user.profile.account_counts.tiktok,
                    'VK AdAccounts': this.props.user.profile.account_counts.vk,
                    'myTarget AdAccounts': this.props.user.profile.account_counts.mytarget,
                    'Yandex AdAccounts': this.props.user.profile.account_counts.yandex,
                    'Avito AdAccounts': this.props.user.profile.account_counts.avito,
                  })
                  Mixpanel.identify(this.props.email)

                  if (
                    this.props.user.has_profile &&
                    !this.props.user.profile.new_aitarget_offer &&
                    this.props.user.profile.closedoc_type === CloseDocTypes.PAYMENT
                  ) {
                    this.props.openAppModal({ name: MODALS.NEW_OFFER })
                  }

                  if (this.props.user.has_profile) {
                    this.props
                      .getUserPlugins()
                      .then(() => {
                        this.props.setLoaded(true)

                        Mixpanel.set({
                          Audiences: this.props.user.profile.smart_targetings ? 'Yes' : 'No',
                          'Feed Manager': this.props.user.profile.feedgen ? 'Yes' : 'No',
                          Optimizer: this.props.user.profile.optimizer ? 'Yes' : 'No',
                        })
                        Mixpanel.identify(this.props.email)
                      })
                      .catch(baseCatchError)

                    this.props.getUserAvailablePlugins().catch(baseCatchError)
                    this.props.getUserVotes().catch(baseCatchError)
                    this.props.getAvailablePolls().catch(baseCatchError)
                    this.props.getReferral().catch(baseCatchError)
                  } else {
                    this.props.setLoaded(true)
                  }
                }
              })
              .catch(baseCatchError)
          } else {
            this.props.logout()
          }
        })
        .catch(this.setLoadedWithoutAuth)
    } else {
      this.setLoadedWithoutAuth()
    }
  }

  public render() {
    return this.props.loaded ? this.props.children : <Loader />
  }

  public setLoadedWithoutAuth() {
    this.props.setAuthorized(false)
    this.props.setLoaded(true)
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  authorized: state.user.isAuthorized,
  email: state.user.email,
  loaded: state.app.loaded,
  user: state.user,
})

const mapDispatchToProps = (dispatch: MyThunkDispatch) => ({
  getProfile: () => dispatch(getProfile()),
  getReferral: () => dispatch(getReferral()),
  getUserAvailablePlugins: () => dispatch(getUserAvailablePlugins()),
  getAvailablePolls: () => dispatch(getAvailablePolls()),
  getUserVotes: () => dispatch(getUserVotes()),
  getUserInfo: () => dispatch(getUserInfo()),
  getUserPlugins: () => dispatch(getUserPlugins()),
  logout: () => dispatch(logoutUser()),
  refreshToken: (token: string | null) => dispatch(refreshToken(token)),
  setAuthorized: (authorized: boolean) => dispatch(setAuthorized(authorized)),
  setLoaded: (loaded: boolean) => dispatch(setAppLoaded(loaded)),
  openAppModal: (args: IModal) => dispatch(openAppModal(args)),
})

const StartupClass = connect<IStartupPropsFromState, any, any, any>(mapStateToProps, mapDispatchToProps)(Startup)

export default StartupClass

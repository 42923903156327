import { MODALS } from './CampaignReport/modals/types'
import { DirectionOption, EquivalentOption } from './components/LimitForm/types'

import { TG_PARTNER_IDS } from '@/types'

export interface IModal {
  name: MODALS
  data: any
}

export enum FilterStatus {
  ACTIVE = 'Active',
  IN_REVIEW = 'In Review',
  ON_HOLD = 'On Hold',
  STOPPED = 'Stopped',
  DECLINED = 'Declined',
  MIXED = 'Mixed',
}

export enum FilterType {
  COMPANY = 'company',
  GROUP = 'group',
  ADS = 'ads',
}

export enum TableColumnType {
  ID = 'id',
  NAME = 'name',
  STATUS = 'status',
  DAILY_BUDGET = 'daily_budget',
  DAY_STOP = 'day_stop',
  IMPRESSIONS = 'impressions',
  JOINED = 'joined',
  CPM = 'cpm',
  CPS = 'cps',
  CLICKS = 'clicks',
  CTR = 'ctr',
  CPC = 'cpc',
  SPENT = 'spent',
  BUDGET = 'budget',
  TEXT = 'text',
  URL = 'url',
  START_DATE = 'start_date',
  AD_ID = 'ad_id',
  TARGETINGS = 'targetings',
  CAMPAIGN_ID = 'campaign_id',
  ADSET_ID = 'adset_id',
  TOKEN = 'ad_info',
  SELECT = 'select',
}

export enum ExtraFilterType {
  UNDISTRIBUTED = 'undistributed',
}

export type FilterTabsType = Omit<FilterType, ExtraFilterType.UNDISTRIBUTED>

export type ExtendedFilterType = FilterType | ExtraFilterType

export enum FilterSubtype {
  STATUS = 'status',
}

export interface IFilterStepsData {
  index: number
  type: FilterType
  subtype: FilterSubtype[]
  statuses: FilterStatus[]
  names: string[]
}

export interface ITabs {
  type: FilterTabsType
  selectedCount: number | null
  count: number | null
  active: boolean
}

export enum DailyStatus {
  NEW = 'new',
  SUCCESS = 'success',
  FAILED = 'failed',
  STOPPED = 'stopped',
}

export enum DailyLevel {
  CAMPAIGN = 'campaign',
  ADSET = 'adset',
  AD = 'ad',
  UNDISTRIBUTED = 'undistributed',
}

export interface IDailyBudget {
  id: number
  telegramad_account: number
  amount: number
  campaign: number | true
  adset: number | null
  ad: number | null
  status: DailyStatus
  level: DailyLevel
  budget_adsets: number[] | null
  budget_ads: number[] | null
  created_at: string | null
  updated_at: string
  rule: RuleRequestBody | null
}

export interface IStopDate {
  id: number
  telegramad_account: number
  date_stop: string
  campaign: number | true
  adset: number | null
  ad: number | null
  status: DailyStatus
  level: DailyLevel
  created_at: string | null
  updated_at: string
  rule: RuleRequestBody | null
}

export interface ITargetings {
  languages: number
  raw_languages: number
  topics: number
  channels: number
  exclude_topics: number
  exclude_channels: number
}

export interface IDataListShared {
  [TableColumnType.BUDGET]: number | null
  [TableColumnType.CLICKS]: number | null
  [TableColumnType.CPC]: number | null
  [TableColumnType.CPM]: number | null
  [TableColumnType.CPS]: number | null
  [TableColumnType.CTR]: number | null
  [TableColumnType.DAILY_BUDGET]: IDailyBudget | null
  [TableColumnType.DAY_STOP]: IStopDate | null
  [TableColumnType.ID]: number
  [TableColumnType.IMPRESSIONS]: number | null
  [TableColumnType.JOINED]: number | null
  [TableColumnType.NAME]: string | null
  [TableColumnType.SPENT]: number | null
  [TableColumnType.START_DATE]: string | null
  [TableColumnType.STATUS]: FilterStatus
}

export type Ads = {
  ad_id: number | null
  ad_info: number | null
  adset_id: number | null
  adsets_count: number | null
  campaigns_count: number | null
  targetings: ITargetings | null
  text: string | null
  url: string | null
  partner_id: TG_PARTNER_IDS | null
  [TableColumnType.BUDGET]: number | null
  [TableColumnType.CLICKS]: number | null
  [TableColumnType.CPC]: number | null
  [TableColumnType.CPM]: number | null
  [TableColumnType.CPS]: number | null
  [TableColumnType.CTR]: number | null
  [TableColumnType.DAILY_BUDGET]: IDailyBudget | null
  [TableColumnType.DAY_STOP]: IStopDate | null
  [TableColumnType.ID]: number
  [TableColumnType.IMPRESSIONS]: number | null
  [TableColumnType.JOINED]: number | null
  [TableColumnType.NAME]: string | null
  [TableColumnType.SPENT]: number | null
  [TableColumnType.START_DATE]: string | null
  [TableColumnType.STATUS]: FilterStatus
}

export type Adsets = {
  ads_count: number
  campaign_id: number | null
}

export type Campaigns = {
  ads_count: number
  adsets_count: number
}

export type AdsResult = Ads & IDataListShared
export type AdsetsResult = Adsets & IDataListShared
export type CampaignsResult = Campaigns & IDataListShared

type AllAdsResults = {
  page: number
  results: AdsResult[]
}

type AllAdsetsResults = {
  page: number
  results: AdsetsResult[]
}

type AllCampaignsResults = {
  page: number
  results: CampaignsResult[]
}

export enum SharedDataType {}

enum DataType {
  COUNT = 'count',
  CURRENT = 'current',
  NEXT = 'next',
  PAGE_COUNT = 'page_count',
  PREVIOUS = 'previous',
  LOADING = 'loading',
  RESULTS = 'results',
  ALLRESULTS = 'allresults',
  REQUEST_ERROR = 'request_error',
}

export type DataSharedType = {
  [DataType.COUNT]: number
  [DataType.CURRENT]: number
  [DataType.NEXT]: number | null
  [DataType.PAGE_COUNT]: number
  [DataType.PREVIOUS]: number | null
  [DataType.LOADING]: boolean
  [DataType.REQUEST_ERROR]: boolean
}

export type AdsData = {
  results: AdsResult[] | []
  allResults: AllAdsResults[] | []
}

export type AdsetsData = {
  results: AdsetsResult[] | []
  allResults: AllAdsetsResults[] | []
}

export type CampaignsData = {
  results: CampaignsResult[] | []
  allResults: AllCampaignsResults[] | []
}

export type AdsDataList = AdsData & DataSharedType
export type AdsetsDataList = AdsetsData & DataSharedType
export type CampaignsDataList = CampaignsData & DataSharedType

interface ITableDataCount {
  adsData: number
  campaignData: number
  adsetData: number
  undistributedData: number
  loading: boolean
}

interface ISettings {
  ads: TableColumnType[] | []
  adsets: TableColumnType[] | []
  campaigns: TableColumnType[] | []
}

// @todo in the future needs to rename:
// group -> adset, plural -> adsets
// ads -> ad, plural -> ads
// company -> campaign, plural -> campaigns

export interface IFilterSteps {
  filters: IFilterStepsData[]
  modals: IModal | null
  tabs: ITabs[]
  adsData: AdsDataList
  campaignData: CampaignsDataList
  adsetData: AdsetsDataList
  undistributedData: AdsDataList
  tableDataCount: ITableDataCount
  campaignSelectedList: CampaignsDataList
  accountSelectedList: AdsDataList
  adsetSelectedList: AdsetsDataList
  settings: ISettings
  hasActiveAccounts: {
    hasActive: boolean
    loading: boolean
  }
  selectedUndistributedIds: string[]
  selectedAdIds: string[]
  selectedAdsetIds: string[]
  selectedCampaignIds: string[]
  counter: ICounter
  selectAllRowsType: {
    [key in ExtendedFilterType]: boolean
  }
  isUndistributedError: boolean
  isAdError: boolean
  isAdsetError: boolean
  isCampaignError: boolean
  isUndistributedLoading: boolean
  isAdLoading: boolean
  isAdsetLoading: boolean
  isCampaignLoading: boolean
  isForceUpdateLoading: boolean
  aside: IAside
}

export type RenameHandlerType = {
  name: string
  id: string
}

export interface ICounter {
  count_group_in_company: number
  count_ads_in_company: number
  count_ads_in_group: number
  count_ads: number
  count_group: number
  count_company: number
  count_undistributed: number
  count_all_ads: number
  count_all_groups: number
  count_all_companies: number
  count_all_undistributed: number
  statuses_ads: FilterStatus[]
  statuses_group: FilterStatus[]
  statuses_company: FilterStatus[]
  statuses_undistributed: FilterStatus[]
  selected_ads: AdsResult[] | []
  selected_adsets: AdsetsResult[] | []
  selected_campaigns: CampaignsResult[] | []
  selected_undistributed: AdsResult[] | []
}

export enum AnalyticNames {
  campaign = 'InCampaigns',
  group = 'InGroups',
  ads = 'InAds',
}

export interface ICurrentAccount {
  name: string
  id: string
}

export enum MassEditingTypes {
  limit = 'limit',
  schedule = 'schedule',
  stop = 'stop',
}

interface Limit {
  budget_id: number | null
  created_at: string | null
  amount: number
  rule: RuleRequestBody | null
}

interface Stop {
  stop_id: number | null
  created_at: string | null
  date: string | null
  rule: RuleRequestBody | null
}

export interface AsideValues {
  id: number
  type: DailyLevel
  name: string | null
  limit: Limit
  stop: Stop
}

export type AsideLimitDraft = {
  limit: number
  equivalent: EquivalentOption
  direction: DirectionOption
  rule: Rule | null
}

export type AsideStopDateDraft = {
  stopDate: string | null
  rule: Rule | null
}

export interface IAsideNavErrors {
  isError: boolean
  errorMessage: string | undefined
}

interface IAside {
  isOpened: boolean
  activeNavItem: MassEditingTypes
  navErrors: { [key in MassEditingTypes]: IAsideNavErrors }
  navDisabled: { [key in MassEditingTypes]: boolean }
  isLoading: boolean
  asideLimitDraft: AsideLimitDraft | null
  asideStopDateDraft: AsideStopDateDraft | null
  asideValues: AsideValues[] | []
  allAsideValues: AsideValues[] | []
  isAll: boolean
  isSetStopDateRule: boolean
  isSetLimitRule: boolean
}

export const enum Inherits {
  FATHER = 'father',
  OWNER = 'owner',
  CHILD = 'child',
}

export interface IInherits {
  [Inherits.FATHER]: boolean
  [Inherits.OWNER]: boolean
  [Inherits.CHILD]: boolean
}

interface IInheritsText {
  [Inherits.FATHER]?: string | JSX.Element
  [Inherits.CHILD]?: string | JSX.Element
  [Inherits.OWNER]?: string | JSX.Element
}

export interface IInheritsModalText {
  [DailyLevel.CAMPAIGN]: IInheritsText
  [DailyLevel.ADSET]: IInheritsText
  [DailyLevel.AD]: IInheritsText
  [DailyLevel.UNDISTRIBUTED]: IInheritsText
}

export const enum Budgets {
  BUDGETS = 'budgets',
}

export const enum DaysStop {
  DAYS_STOP = 'days_stop',
  RULE = 'rule',
}

export const enum Budget {
  LEVEL = 'level',
  LEVEL_ID = 'level_id',
  AMOUNT = 'amount',
}

export interface IBudget {
  [Budget.LEVEL]: DailyLevel
  [Budget.LEVEL_ID]: number
  [Budget.AMOUNT]: number
}

export type IUpdateBudget = Omit<IBudget, Budget.LEVEL>

export type IDeleteBudget = Pick<IBudget, Budget.LEVEL_ID>

export interface ISetBudgets {
  [Budgets.BUDGETS]: IBudget[]
}

export interface IUpdateBudgets {
  [Budgets.BUDGETS]: IUpdateBudget[]
}

export interface IDeleteBudgets {
  [Budgets.BUDGETS]: IDeleteBudget[]
}

export const enum Days {
  LEVEL = 'level',
  LEVEL_ID = 'level_id',
  DATE_STOP = 'date_stop',
  RULE = 'rule',
}

export interface IDaysStop {
  [Days.LEVEL]: DailyLevel
  [Days.LEVEL_ID]: number
  [Days.DATE_STOP]: string | null
}

export type IUpdateDayStop = Omit<IDaysStop, Days.LEVEL>

export type IDeleteDayStop = Pick<IDaysStop, Days.LEVEL_ID>

export interface ISetDaysStop {
  [DaysStop.DAYS_STOP]: IDaysStop[]
  [DaysStop.RULE]?: RuleRequestBody
}

export interface IUpdateDaysStop {
  [DaysStop.DAYS_STOP]: IUpdateDayStop[]
  [DaysStop.RULE]?: RuleRequestBody
}

export interface IDeleteDaysStop {
  [DaysStop.DAYS_STOP]: IDeleteDayStop[]
}

export enum MetricsType {
  VIEWS = 'views',
}

export type MetricsOption = {
  value: TableColumnType | MetricsType.VIEWS
  label: string | JSX.Element
}

export const enum PERIODS {
  ALL = 'all',
  DAY = 'day',
}

export type PeriodsOption = {
  value: PERIODS
  label: string
}

export const enum SYMBOLS {
  MORE_OR_EQUAL = '>=',
  LESS_OR_EQUAL = '<=',
}

export type SymbolsOption = {
  value: SYMBOLS
  label: SYMBOLS
}

export const enum CONDITIONS {
  OR = 'or',
  AND = 'and',
}

export const enum RULES_FIELDS {
  CONDITION = 'condition',
  TARGETS = 'targets',
}

export const enum TARGETS_FIELDS {
  NAME = 'name',
  PERIOD = 'period',
  SYMBOL = 'symbol',
  VALUE = 'value',
  LIMIT = 'limit',
}

export type Targets = {
  [TARGETS_FIELDS.NAME]: MetricsOption
  [TARGETS_FIELDS.PERIOD]: PeriodsOption
  [TARGETS_FIELDS.SYMBOL]: SymbolsOption
  [TARGETS_FIELDS.VALUE]: number
}

export type TargetsRequestBody = {
  [TARGETS_FIELDS.NAME]: TableColumnType | MetricsType.VIEWS
  [TARGETS_FIELDS.PERIOD]: PERIODS
  [TARGETS_FIELDS.SYMBOL]: SYMBOLS
  [TARGETS_FIELDS.VALUE]: number
}

export type Rule = {
  [RULES_FIELDS.CONDITION]: CONDITIONS
  [RULES_FIELDS.TARGETS]: Targets[]
}

export type RuleRequestBody = {
  [RULES_FIELDS.CONDITION]: CONDITIONS
  [RULES_FIELDS.TARGETS]: TargetsRequestBody[]
}

export const enum DirectionType {
  INCREASE_BY = 'increase_by',
  DECREASE_BY = 'decrease_by',
  SET_IN = 'set_in',
}

export const enum EquivalentType {
  EURO = 'euro',
  PERCENT = 'percent',
}

import React from 'react'

import { BackgroundWhite } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = BackgroundWhite } = props
  const defaultStyle = { width: 40, height: 40 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5529_5539)">
        <mask
          id="mask0_5529_5539"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="40"
          height="40"
        >
          <path d="M40 0H0V40H40V0Z" fill={fill} />
        </mask>
        <g mask="url(#mask0_5529_5539)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M40 0H0V29V40H11H40V0ZM11 40C17.0751 40 22 35.0751 22 29C22 22.9249 17.0751 18 11 18C4.92487 18 0 22.9249 0 29C0 35.0751 4.92487 40 11 40ZM11.7933 11.7574L14.0332 7.10567H16.4809V14.137H14.7643V9.10541L12.5746 13.3557H11.0121L8.82241 9.10541V14.137H7.10938V7.10567H9.55349L11.7933 11.7574ZM25.0674 8.50692V7.10567H32.905V8.50692H29.8409L29.8338 14.137H28.1207L28.1279 8.50692H25.0674ZM31.1883 30.547C31.1453 30.8265 31.0342 31.0594 30.8515 31.2315C30.6795 31.3963 30.4501 31.511 30.1634 31.5791C29.8803 31.6436 29.4681 31.683 29.0273 31.683C28.5865 31.683 28.1708 31.6257 27.8304 31.5074C27.5007 31.3963 27.2391 31.1992 27.0563 30.9233C26.8699 30.6473 26.7768 30.2459 26.7768 29.737V29.0167C26.7768 28.5042 26.8699 28.1064 27.0563 27.8305C27.2391 27.5545 27.5007 27.361 27.8304 27.2463C28.1708 27.1317 28.5722 27.0707 29.0273 27.0707C29.4825 27.0707 29.8803 27.1102 30.1634 27.1747C30.4501 27.2392 30.6795 27.3574 30.8515 27.5223C31.0307 27.6979 31.1453 27.9272 31.1883 28.2068H32.905C32.8584 27.6262 32.6613 27.1317 32.3172 26.7374C32.0019 26.3755 31.5682 26.1103 31.0271 25.949C30.5003 25.7913 29.8122 25.7089 29.0309 25.7089C28.2103 25.7089 27.4971 25.8272 26.9094 26.0601C26.3144 26.2966 25.8557 26.6909 25.5368 27.232C25.2322 27.7516 25.0745 28.4433 25.0637 29.2927V29.3715L25.1569 29.3751L25.0637 29.3787V29.4575C25.0745 30.3069 25.2322 30.9985 25.5368 31.5182C25.8521 32.0593 26.3144 32.4535 26.9094 32.69C27.4935 32.923 28.2067 33.0413 29.0309 33.0413C29.8516 33.0413 30.5039 32.9588 31.0271 32.8011C31.5682 32.6399 32.0019 32.3747 32.3172 32.0127C32.6613 31.6185 32.8584 31.124 32.905 30.5434L31.1883 30.547Z"
            fill={fill}
          />
        </g>
      </g>
      <g clipPath="url(#clip1_5529_5539)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11 39C16.5228 39 21 34.5228 21 29C21 23.4772 16.5228 19 11 19C5.47715 19 1 23.4772 1 29C1 34.5228 5.47715 39 11 39ZM22 29C22 35.0751 17.0751 40 11 40C4.92487 40 0 35.0751 0 29C0 22.9249 4.92487 18 11 18C17.0751 18 22 22.9249 22 29Z"
          fill={fill}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.82494 28.8744C8.04616 27.6043 10.1941 26.767 11.2689 26.3624C14.3375 25.2073 14.9751 25.0067 15.3907 25.0001C15.4822 24.9986 15.6865 25.0191 15.8189 25.1163C15.9307 25.1984 15.9615 25.3093 15.9762 25.3871C15.9909 25.465 16.0092 25.6423 15.9947 25.7808C15.8284 27.3621 15.1088 31.1993 14.7428 32.9703C14.5879 33.7197 14.2829 33.971 13.9876 33.9955C13.346 34.049 12.8587 33.6118 12.2372 33.2431C11.2647 32.6661 10.7153 32.307 9.77127 31.744C8.68032 31.0934 9.38754 30.7358 10.0093 30.1514C10.172 29.9984 12.9992 27.6711 13.054 27.46C13.0608 27.4336 13.0671 27.3352 13.0025 27.2832C12.9379 27.2312 12.8426 27.249 12.7738 27.2631C12.6762 27.2832 11.1226 28.2125 8.11292 30.0511C7.67193 30.3252 7.2725 30.4587 6.91462 30.4517C6.52009 30.444 5.76117 30.2498 5.19699 30.0838C4.505 29.8803 3.95502 29.7727 4.00291 29.4269C4.02785 29.2469 4.30186 29.0627 4.82494 28.8744Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_5529_5539">
          <rect width="40" height="40" rx="20" fill={fill} />
        </clipPath>
        <clipPath id="clip1_5529_5539">
          <rect width="22" height="22" fill={fill} transform="translate(0 18)" />
        </clipPath>
      </defs>
    </svg>
  )
}

import { AdAccountTypes } from '../adaccounts/types'

import { TG_PARTNER_IDS } from '@/types'

export enum TransactionsActionTypes {
  SET_TRANSACTIONS = 'SET_TRANSACTIONS',
}

export type TransactionType = 'debit' | 'credit'
export type TransactionStatus = 'draft' | 'processed'

export interface ITransaction {
  id: number
  created_at: string
  amount: string
  transaction_reason: string
  transaction_type: TransactionType
  transaction_status: TransactionStatus
  profile_name: string
  account_name: string
  payment: number
  account_type: string
  subreason: string
  helptext: string
  is_autopayment: boolean
  account_partner_id: TG_PARTNER_IDS | null
}

export interface ITransactionsState {
  page_count: number
  count: number
  current: number
  next: string | null
  previous: string | null
  results: ITransaction[]
}

export interface ITransactionReason {
  slug_field: string
  display_name: string
}

export interface ITransactionAccount {
  account_id: string
  account_type: AdAccountTypes
  id: number
  name: string
}

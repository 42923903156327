import React from 'react'

import { SlateGreyLighten43 } from '../../const/colors'
import { IIcons } from './types'

export default (props: IIcons) => {
  const { style, fill = SlateGreyLighten43 } = props
  const defaultStyle = { width: 22, height: 22 }

  return (
    <svg style={{ ...defaultStyle, ...style }} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-0.000244141" y="0.000549316" width="22.0005" height="22.0005" rx="11.0002" fill={fill} />
      <path
        d="M7.00127 14.9995L7.64376 15.6534L7.64947 15.6477L7.00127 14.9995ZM6.35778 14.3467C5.99666 14.7015 5.99154 15.2819 6.34636 15.643C6.70118 16.0041 7.28157 16.0093 7.6427 15.6544L6.35778 14.3467ZM15.6484 7.64874C16.0064 7.29076 16.0064 6.71034 15.6484 6.35235C15.2905 5.99437 14.71 5.99437 14.352 6.35235L15.6484 7.64874ZM7.64955 6.35244C7.29161 5.9944 6.7112 5.99433 6.35316 6.35227C5.99513 6.71021 5.99505 7.29063 6.35299 7.64866L7.64955 6.35244ZM14.352 15.6487C14.71 16.0067 15.2905 16.0067 15.6484 15.6487C16.0064 15.2908 16.0064 14.7103 15.6484 14.3524L14.352 15.6487ZM6.35881 14.3457L6.35778 14.3467L7.6427 15.6544L7.64373 15.6534L6.35881 14.3457ZM7.64947 15.6477L11.6484 11.6487L10.352 10.3524L6.35308 14.3513L7.64947 15.6477ZM11.6484 11.6487L15.6484 7.64874L14.352 6.35235L10.352 10.3524L11.6484 11.6487ZM11.6485 10.3524L7.64955 6.35244L6.35299 7.64866L10.352 11.6487L11.6485 10.3524ZM10.352 11.6487L14.352 15.6487L15.6484 14.3524L11.6484 10.3524L10.352 11.6487Z"
        fill="white"
      />
    </svg>
  )
}

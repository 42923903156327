import { TG_PARTNER_IDS } from '@/types'

export enum AdAccountsActionTypes {
  SET_AD_ACCOUNTS = 'SET_AD_ACCOUNTS',
  SET_AD_ACCOUNT = 'SET_AD_ACCOUNT',
  UPDATE_AD_ACCOUNT = 'UPDATE_AD_ACCOUNT',
  UPDATE_AD_ACCOUNT_ONBOARDING = 'UPDATE_AD_ACCOUNT_ONBOARDING',
  SET_AD_ACCOUNT_AUTOPAYMENT = 'SET_AD_ACCOUNT_AUTOPAYMENT',
  SET_PAGINATION_OBJECT = 'SET_PAGINATION_OBJECT',
  SET_ACCOUNTS_ERROR = 'SET_ACCOUNTS_ERROR',
  SET_ACCOUNTS_LOADING = 'SET_ACCOUNTS_LOADING',
}

export type AdAccountTypes =
  | 'facebook'
  | 'google'
  | 'vk'
  | 'mytarget'
  | 'tiktok'
  | 'yandex'
  | 'vkads'
  | 'yandex_promo_page'
  | 'vk_adsmarket'

export type AdAccountExtraTypes =
  | AdAccountTypes
  | 'archived_accounts'
  | 'avito'
  | 'telegram'
  | 'yandex_business'
  | 'yandex_promo_page'
  | 'vk_adsmarket'

export enum AdAccountStatus {
  CREATING = 'creating',
  READY = 'ready',
}

export enum AvitoAccountStatus {
  CHECKING = 'checking',
  DENIED_INN = 'denied_inn',
  DENIED_COMPANY = 'denied_company',
  ALREADY_ADVERTISED = 'already_advertised',
  DONE = 'done',
}

export enum YandexPromoAccountStatus {
  READY = 'yes',
  DENIED = 'no',
  NOT_SET = 'not_set',
  CORRECT = 'correct',
}

export enum YandexPromoTransactionStatus {
  DRAFT = 'draft',
  PROCESSED = 'processed',
}

export enum TelegramAccountStatus {
  READY = 'yes',
  DENIED = 'no',
  NOT_SET = 'not_set',
  CORRECT = 'correct',
}

export enum TelegramTransactionStatus {
  DRAFT = 'draft',
  PROCESSED = 'processed',
}

export enum VKAdsMarketAccountStatus {
  READY = 'yes',
  DENIED = 'no',
  NOT_SET = 'not_set',
  CORRECT = 'correct',
}

export interface IVKAdsMarketTransaction {
  first_refills_drafts: boolean
  first_refills_processed: boolean
  has_first_refills: boolean
}

export interface IAdAccount {
  readonly id: number
  readonly account_balance?: number
  readonly account_type: AdAccountTypes
  readonly customer_id: string
  readonly business_name: string
  readonly adaccount_id_fb: string
  readonly name: string
  readonly industry?: string | null
  readonly subindustry?: string | null
  readonly inn_type?: string | null
  readonly status: AdAccountStatus
  readonly ads_manager_link: string
  readonly account_id: number
  readonly optimizer_counts: null | number
  readonly remaining_days_spend: string
  readonly is_archived: boolean
  readonly fb_account_status: string
  readonly website: string | null
  readonly available_for_transfer: boolean
  readonly tiktok_account_status: string
  readonly mytarget_account_status: string
  readonly account_hints: string[]
  readonly login: string | null
  readonly autopayment: IAdAccountAutoPayment | null
  readonly profile_id: number
  readonly request_status: AvitoAccountStatus &
    TelegramAccountStatus &
    YandexPromoAccountStatus &
    VKAdsMarketAccountStatus
  readonly inn: string
  readonly client_id: string | null
  readonly kpp: string | null
  readonly transactions: boolean | IVKAdsMarketTransaction
  readonly first_transaction_status: TelegramTransactionStatus | YandexPromoTransactionStatus | null
  readonly is_notify_closed: boolean
  readonly min_refill_rub_amount: number | null
  readonly need_bigdata_targeting: boolean
  readonly need_mts_pro: boolean
  readonly partner_id: TG_PARTNER_IDS | null
}

export interface IAdAccountObject {
  page_count: number
  count: number
  current: number
  next: number | null
  previous: number | null
  results: IAdAccount[]
}

export interface IAdAccountsState {
  telegram: IPlatformState
  yandex_promo_page: IPlatformState
  avito: IPlatformState
  facebook: IPlatformState
  google: IPlatformState
  vk: IPlatformState
  vkads: IPlatformState
  vk_adsmarket: IPlatformState
  archived_accounts: IPlatformState
  mytarget: IPlatformState
  tiktok: IPlatformState
  yandex_business: IPlatformState
  profi: IPlatformState
  yandex: IPlatformState
  pagination_object: IAdAccountObject
}

export interface ISubindustry {
  id: number
  name: string
}

export interface IIndustry {
  id: number
  name: string
  subindustries: ISubindustry[]
}

export interface IAccountBody {
  industry: string
  website: string
  subindustry?: string
  vk_users_ids?: string
  advertise_system?: string
  login?: string
  client_id?: number
  vk_profiles?: IClientVKProfileBody[]
  manager_emails?: IClientMtManagerBody[]
  inn_type?: string
  inn?: string
}

export interface IAvitoRequestBody {
  phone: string
  industry: string
  subindustry?: string
  inn?: string
}

export interface IClientVKProfileBody {
  vk_profile: string
}

export interface IClientMtManagerBody {
  manager_email: string
}

export interface IAdAccountUpdateBody {
  is_archived?: boolean
  client_id?: string
  is_notify_closed?: boolean
  need_bigdata_targeting?: boolean
}

export interface IAccountOnboardingBody {
  setup_hint: string
  hint_status: 'done' | 'not done'
  facebook_account?: number
  google_ads_account?: number
  vk_account?: number
  mytarget_account?: number
  tiktok_account?: number
  yandex_account?: number
}

export interface IAutoPaymentBody {
  is_enabled: boolean
  minimal_balance?: number
  amount?: number
  max_amount?: number
  max_amount_period?: string
  facebook_account?: number
  google_ads_account?: number
  vk_account?: number
  mytarget_account?: number
  tiktok_account?: number
  yandex_account?: number
  id?: number
}

export interface IAdAccountAutoPayment {
  id: number
  is_enabled: boolean
}

export type AdAccountsPageType = number | 'all'

export interface IYandexCredentials {
  login: string
  password: string
}

export interface IPlatformState {
  accounts: IAdAccount[]
  error: any
  isLoading: boolean
}

import { createUseStyles } from 'react-jss'

import {
  BackgroundGrey,
  PurpleBase,
  SlateGreyLighten22,
  SlateGreyLighten43,
  TextGreyBase,
  TextSlateGreyLighten8,
  TextSlateGreyLighten16,
} from '@/const/colors'
import { baseFontFamily, baseTransDur, smallFont } from '@/const/commonStyles'

export default createUseStyles(
  {
    navLink: {
      position: 'relative',
      height: 60,
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.2px',
      font: `500 ${smallFont} ${baseFontFamily}`,
      textDecoration: 'none',
      color: TextGreyBase,

      '& svg path, & svg rect': {
        fill: SlateGreyLighten22,
      },

      '&.active': {
        color: PurpleBase,

        '& svg path, & svg rect': {
          fill: PurpleBase,
        },
      },

      '&$isDashed': {
        color: TextSlateGreyLighten8,
        borderTop: `1px dashed ${SlateGreyLighten43}`,

        '&.active': {
          color: PurpleBase,
        },
      },

      '&:hover': {
        backgroundColor: BackgroundGrey,
        transitionDuration: baseTransDur,
      },
    },
    isDashed: {},
    menuLabel: {
      color: TextSlateGreyLighten16,
    },
  },
  { name: 'SubMenu' }
)

import { createSelector } from 'reselect'

import { getApp, getBrowser, getProfile, getReferral } from '@/common/selectors'
import { getIsNotViewable } from '@/common/utils'

export const IsNotViewableDashboardsFeature = 'isNotViewableDashboardsFeature'
export const IsNotViewableReportFeature = 'isNotViewableReportFeature'
export const IsNotViewableInviteFriend = 'isNotViewableInviteFriend'
export const IsNotViewableImportVKFeature = 'isNotViewableImportVKFeature'
export const IsNotViewableTrackerFeature = 'isNotViewableTrackerFeature'
export const IsNotViewableTelegramFeature = 'isNotViewableTelegramFeature'
export const IsNotViewableVKAdsFeature = 'isNotViewableVKAdsFeature'
export const IsNotViewableCampaignEditorTelegramFeature = 'isNotViewableCampaignEditorTelegramFeature'
export const IsNotViewableAdsMarkingFeature = 'isNotViewableAdsMarkingFeature'
export const IsNotViewableFreeServicesFeature = 'isNotViewableFreeServicesFeature'
export const IsNotViewableFinancialTermsLink = 'isNotViewableFinancialTermsLink'

const getIsNotViewableFeature = () => getIsNotViewable(IsNotViewableDashboardsFeature)
const getIsNotViewableReportFeature = () => getIsNotViewable(IsNotViewableReportFeature)
const getIsNotViewableInviteFriend = () => getIsNotViewable(IsNotViewableInviteFriend)
const getIsNotViewableImportVKFeature = () => getIsNotViewable(IsNotViewableImportVKFeature)
const getIsNotViewableTrackerFeature = () => getIsNotViewable(IsNotViewableTrackerFeature)
const getIsNotViewableTelegramFeature = () => getIsNotViewable(IsNotViewableTelegramFeature)
const getIsNotViewableVKAdsFeature = () => getIsNotViewable(IsNotViewableVKAdsFeature)
const getIsNotViewableCampaignEditorTelegramFeature = () => getIsNotViewable(IsNotViewableCampaignEditorTelegramFeature)
const getIsNotViewableAdsMarkingFeature = () => getIsNotViewable(IsNotViewableAdsMarkingFeature)
const getIsNotViewableFreeServicesFeature = () => getIsNotViewable(IsNotViewableFreeServicesFeature)
const getIsNotViewableFinancialTermsLink = () => getIsNotViewable(IsNotViewableFinancialTermsLink)

export default createSelector(
  [
    getApp,
    getBrowser,
    getProfile,
    getReferral,
    getIsNotViewableFeature,
    getIsNotViewableReportFeature,
    getIsNotViewableInviteFriend,
    getIsNotViewableImportVKFeature,
    getIsNotViewableTrackerFeature,
    getIsNotViewableTelegramFeature,
    getIsNotViewableVKAdsFeature,
    getIsNotViewableCampaignEditorTelegramFeature,
    getIsNotViewableAdsMarkingFeature,
    getIsNotViewableFreeServicesFeature,
    getIsNotViewableFinancialTermsLink,
  ],
  (
    app,
    browser,
    profile,
    referral,
    isNotViewableDashboardsFeature,
    isNotViewableReportFeature,
    isNotViewableInviteFriend,
    isNotViewableImportVKFeature,
    isNotViewableTrackerFeature,
    isNotViewableTelegramFeature,
    isNotViewableVKAdsFeature,
    isNotViewableCampaignEditorTelegramFeature,
    isNotViewableAdsMarkingFeature,
    isNotViewableFreeServicesFeature,
    isNotViewableFinancialTermsLink
  ) => ({
    isDesktop: browser.greaterThan.medium,
    isFinanceMenuOpened: app.isFinanceMenuOpened,
    isProfileClient: profile.is_profile_client,
    profileType: profile.profile_type,
    isViewAdsMarking: ![profile.is_profile_agency, profile.is_profile_client].some(Boolean),
    isToolMenuOpened: app.isToolMenuOpened,
    isMenuOpened: app.isMenuOpened,
    isAnalyticsMenuOpened: app.isAnalyticsMenuOpened,
    referral,
    isNotViewableDashboardsFeature,
    isNotViewableReportFeature,
    isNotViewableInviteFriend,
    isNotViewableImportVKFeature,
    isNotViewableTrackerFeature,
    isNotViewableTelegramFeature,
    isNotViewableVKAdsFeature,
    isNotViewableCampaignEditorTelegramFeature,
    isNotViewableAdsMarkingFeature,
    isNotViewableFreeServicesFeature,
    isCashbackEnabled: profile.is_cashback_enabled,
    isNotViewableFinancialTermsLink,
  })
)
